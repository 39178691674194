.jumbotron {
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff;
    --jumbotron-padding-y: 3rem;
}

.jumbotron p:last-child {
    margin-bottom: 0;
}
  
.jumbotron-heading {
    font-weight: 300;
}

.jumbotron .container {
    max-width: 90vw;
}

.lemonboxwelcome {
    height: 60vh;
    background-color: #ceceaf;
    background-image: radial-gradient(circle, #ceceaf, #b9b99a);
    display: flex;
    justify-content: center;
    align-items: center;
}

.appIcon {
    width: 25%;
    padding-bottom: 1rem;
}

.lemonboxicon {
    width: 90%;
}